<template>
    <section id="main">
        <div class="container">
            <div class="content">
                <h1 class="title">404</h1>
                <p class="desciption">[Page not found]</p>
                <router-link to="/" class="button btn">
                    <span>{{ $t('notFoundPage.title') }}</span>
                </router-link>
            </div>
        </div>
    </section>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
#main {
    height: max-content;
    min-height: 100%;
    padding-top: 24px;
    background: url('../../public/images/404Back.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100px;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    margin-top: 0;
    margin-bottom: 14px;
    color: #FFF;
    text-align: center;
    font-family: ZenDots;
    font-size: 110px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.desciption {
    margin-bottom: 40px;
    position: relative;
    color: #FFF;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.desciption::before {
    position: absolute;
    top: 50%;
    left: 0;
    right: auto;
    content: '';
    display: flex;
    width: 76px;
    height: 1px;
    background: #FFF;
}

.desciption::after {
    position: absolute;
    top: 50%;
    right: 0;
    left: auto;
    content: '';
    display: flex;
    width: 76px;
    height: 1px;
    background: #FFF;
}

.button {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    min-width: 225px;
    min-height: 47px;
    font-weight: 500;
}

@media (min-width:1800px) {
    .title {
        font-size: 120px;
    }

    .desciption {
        font-size: 18px;
    }

    .button {
        font-size: 20px;
        min-width: 333px;
        min-height: 56px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    #main {
        background-position: right;
    }
}

@media (max-width: 400px) {
    #main {
        background: url('../../public/images/404BackMobile.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .title {
        font-size: 80px;
    }

    .desciption::after,
    .desciption::before {
        width: 42px;
    }

    .button {
        min-height: 40px;
    }
}
</style>